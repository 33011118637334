<script setup lang="ts">
const { category: activeCategory } = useCategory();
const { loadNavigationElements, navigationElements } = useNavigation();
await loadNavigationElements({ depth: 3 });

const findById: any = (array: [], id: string) => {
  for (const item of array) {
    if(item?.id === id) return item;
    if(item?.children && item.children.length > 0) {
      const innerResult = findById(item.children, id);
      if (innerResult) return innerResult;
    }
  }
}
</script>
<template>
  <div
    v-if="findById(navigationElements, activeCategory.id)?.children.length > 0"
    class="border-t-1 border-maas-border-grey-light w-full py-10"
  >
    <h3 class="text-maas-typography-text-grey mb-8 text-sm">Kategorien</h3>
    <ul>
      <li v-for="item in findById(navigationElements, activeCategory.id).children" class="mb-1.5">
        <NuxtLink :to="'/' + item.seoUrls?.[0].seoPathInfo" class=" text-p text-maas-typography-text-dark underline underline-offset-3 hover:decoration-none">
          {{ item.name }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
